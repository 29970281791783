/*
	GENERAL INFO: V3
	Floating center banner with: close button, overlay and blocking content.
	Implementation ticket: GLOWEB-2296


    Table of contents
    General (includes + variables)
    1. Banner
        1a. Banner Layout
        1b. Header
        1c. Copy
        1d. Buttons
    2. Preference Center
        2a. PC layout
        2b. Header
        2c. Copy
        2d. Buttons
        2e. Consent Settings

    3. Overlay + Helpers

    4. OneTrust Cookie Consent – Banner – US minimalistic design (used in EN-US)
*/

// General (includes + variables)
@charset "utf-8";

$cookie__backdrop--z-index: 5555 !important;


#onetrust-consent-sdk {
	@import "../../bootstrap/functions";
	@import "../../avast/variables";
	@import "../../bootstrap/mixins";
	@import "../../avast/mixins";
	@import "../../avast/components/button";


	//helpers
	// 1. Banner
	// 1a. Banner Layout
	#onetrust-banner-sdk {
		background: $blue-dark!important;
		width: 664px!important;
		max-width: 664px!important;
		border-radius: $base-unit*2;
		padding: $base-unit*2!important;
		box-shadow: none;

		&.otCenterRounded {
			left: 0;
		}

		.ot-sdk-container {
			background: $blue-dark!important;
		}

		#onetrust-policy {
			margin-top: 0px!important;
		}
	}

	// 1b. Header
	.banner-header {
		margin-bottom: $base-unit*1.5;
		display: flex!important;
		flex-direction: row!important;
		justify-content: space-between!important;
		align-items: center!important;

		.banner_logo {
			display: block!important;
			width: 116px!important;
			height: 36px!important;
			background: url("https://static3.avast.com/10002223/web/i/v3/components/avast-logos/avast-logo-inverse.svg")!important;
			background-repeat: no-repeat!important;
		}
	}

	#onetrust-close-btn-container {
		float: none!important;
		display: flex!important;
		flex-direction: row;
		align-items: center;
		margin: 0px!important;
		right: 0!important;
		top: 0!important;
		cursor: pointer!important;

		span {
			color: #fff!important;
			font-size: 12px;
			font-weight: bold;
			margin-right: $base-unit!important;
			//text-transform: uppercase!important;
			cursor: pointer;
		}

		.ot-close-icon {
			width: $base-unit*2!important;
			height: $base-unit*2!important;
			border: 1px solid #fff;
			@include sprite-icon('cross', 32);
			background-size: auto!important;
			background-repeat: no-repeat;
			background-position: 3px!important;
			border-radius: 50%;
			transition: all ease-out .2s!important;
			margin: 0px!important;


			&:focus {
				outline: none!important;
			}
		}

		&:hover {
			opacity: 1!important;

			span {
				text-decoration: underline!important;
			}
		}
	}

	// 1c. Copy
	#onetrust-policy {
		margin-bottom: $base-unit*1.5;

		#onetrust-policy-title {
			margin-bottom: $base-unit!important;
			font-size: $base-unit*1.75!important;
			width: 100%!important;
			color: $white!important;
			padding: 0px!important;
			line-height: $base-unit*2.25!important;
		}

		#onetrust-policy-text {
			margin: 0px!important;
			padding: 0px!important;
			font-size: $base-unit*.875;
			line-height: $base-unit*1.375!important;
			color: $gray07!important;
			font-weight: 400!important;

			a {
				color: $white!important;
				font-weight: 400!important;
				margin-left: 0px!important;
				outline: none!important;
			}
		}
	}

	// 1d. Buttons
	#onetrust-button-group-parent {
		padding: 0px!important;

		#onetrust-button-group {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			text-align: left;

			// accept button
			.banner-actions-container, .onetrust-banner-options {
				max-width: none;
				margin: 0!important;
				display: flex;
				flex-direction: row;

				#onetrust-accept-btn-handler{
					margin: 0px!important;
					display: block!important;
					font-size: 16px!important;
					line-height: $base-unit*1.25!important;
 					letter-spacing: 1px!important;
					height: 56px!important;
					min-height: auto!important;
					padding: 0 $base-unit*1.75!important;
					max-width: none!important;
					min-width: 180px!important;
					order: 0;

					&:hover {
						opacity: 0.7!important;
					}

					// 3rd party
					background-color: #0070F6!important;
					border: none!important;
					color: #fff!important;
					border-radius: 50px!important;
				}
				#cookieGPCControlButton  {
					margin: 0px!important;
					display: block!important;
					font-size: 16px!important;
					min-width: $base-unit*10;
					order: 0;
					padding: 15px 10px;
					text-align: center;
					font-weight: bold;
					text-decoration: none;

					&:hover {
						opacity: 0.7!important;
					}

					// 3rd party
					background-color: #0070F6!important;
					border: none!important;
					color: #fff!important;
					border-radius: 50px!important;
				}
			}


			// reject button
			#onetrust-reject-all-handler {
				background-color: #043E7C!important;
				border: 2px solid #043E7C!important;
				font-size: $base-unit!important;
				line-height: $base-unit*1.25!important;
 				letter-spacing: 1px!important;
				//text-transform: uppercase;
				margin: 0 $base-unit*5 0 $base-unit*1.5!important;
				text-align: center;
				text-decoration: underline;
                border-radius: 50px;
				height: 56px!important;
				min-height: auto!important;
                text-decoration: none;
				padding: 0 $base-unit*1.75!important;
				max-width: none!important;
				min-width: 180px!important;
				order: 2;

				&:hover {
					color: $white!important;
					text-decoration: none!important;
					opacity: 0.7!important;
				}

				&:focus,
				&:active {
					color: $white!important;
					outline: none!important;
					opacity: 1!important;
				}
			}

			// manage button
			#onetrust-pc-btn-handler {
				background-color: transparent!important;
				color: $white!important;
				font-size: $base-unit!important;
				line-height: $base-unit*1.25!important;
				letter-spacing: 1px!important;
				//text-transform: uppercase;
				margin: 0;
				text-align: center;
                border: 2px solid #fff!important;
                border-radius: 50px;
                height: 56px!important;
				min-height: auto!important;
				padding: 0 $base-unit*1.75!important;
				text-decoration: none!important;
				max-width: none!important;
				min-width: 180px!important;

				&:hover {
					color: $white!important;
					text-decoration: none!important;
					opacity: 0.7!important;
				}

				&:focus,
				&:active {
					color: $white!important;
					outline: none!important;
					opacity: 0.7!important;
				}

			}
		}
	}

	// 2. Preference Center
	#onetrust-pc-sdk {
		// 2a. PC layout
		min-width: 664px!important;
		max-width: 664px!important;
		padding: $base-unit*2;
		background-color: $blue-dark!important;
		top: 5%!important;
		bottom: 5%!important;
		border-radius: $base-unit*2;
		overflow-y: auto;

		&.otPcCenter {
			box-shadow: none;
		}

		button {
			&:not(.ot-close-icon) {
				max-width: none!important;
				min-width: 180px!important;
			}
		}

		&.right {
			top: 0!important;
		}

		.ot-pc-refuse-all-handler {
			font-size: $base-unit!important;
		}

		// 2b. Header + Footer
		.ot-pc-header {
			padding: 0px!important;
			border-bottom: 0px!important;
			height: auto!important;
			margin-bottom: $base-unit*1.5;

			.ot-pc-logo {
				display: block;
				background: url("https://static3.avast.com/10002223/web/i/v3/components/avast-logos/avast-logo-inverse.svg")!important;
				background-repeat: no-repeat!important;
				width: 116px!important;
				height: 37px!important;
			}

			#close-pc-btn-handler {
				width: $base-unit*2!important;
				height: $base-unit*2!important;
				position: absolute!important;
				top: 24px!important;
				right: 32px;
				border: none;
				@include sprite-icon('cross', 32);
				background-size: auto!important;
				filter: $filter-white;
				border-radius: 50%;
				transition: all ease-out .2s!important;
				margin: 0px!important;

				span {
					position: absolute;
					top: 8px;
    				right: 41px;
					font-size: 12px!important;
					color: #fff!important;
					font-weight: bold;
					//text-transform: uppercase!important;
					cursor: pointer;
					width: auto!important;
					white-space: nowrap;
				}

				&:hover {
					opacity: 1!important;

					span {
						text-decoration: underline!important;
					}
				}
			}
		}

		.ot-pc-footer {
			border: 0px!important;
			position: static;

			.ot-pc-footer-logo {
				display: none;
			}
		}

		// 2c. Copy + Content
		#ot-pc-content {
			height: auto!important;
			width: 100%!important;
			margin: 0px!important;
			overflow-y: auto!important;
			position: static!important;

			#ot-pc-title {
				color: $white!important;
				margin: 0 0 $base-unit!important;
				font-size: $base-unit*1.75!important;
			}

			#ot-pc-desc {
				color: $gray07;
				float: none!important;
				font-size: $base-unit*.875;
				line-height: $base-unit*1.375;
				margin-bottom: $base-unit*1.5!important;

				br {
					display: none!important;
				}

				a {
					color: $white;
					display: inline!important;
					margin-top: 0px!important;
				}
			}

			#ot-category-title {
				color: $white;
				font-size: $base-unit*1.25!important;
				line-height: $base-unit*1.625;
				padding-bottom: 0!important;
				margin-bottom: $base-unit*1.5;
			}

			.ot-cat-grp {
				margin-top: $base-unit*3!important;

				.ot-accordion-layout {
					margin: 0 0 $base-unit*1.5!important;
					border: none!important;
					width: 100%!important;

					.ot-acc-hdr {
						padding: 0 0 $base-unit*.75!important;
						width: 99%!important;
						font-size: $base-unit*.875!important;

						.ot-arw-cntr {
							right: -16px;
							top: 4%!important;
							transform: rotate(180deg)!important;

							.ot-arw {
								width: 24px!important;
								height: 24px!important;
								color: $gray07!important;
							}
						}

						&.ot-always-active-group {
							.ot-arw-cntr {
								top: 4%!important;
							}
						}

						.ot-cat-header {
							font-size: $base-unit*.875;
							line-height: $base-unit*1.375;
							color: $white;
						}

						.ot-tgl {
							right: 8px;
						}

						.ot-always-active {
							right: 18px;
						}

						.ot-tgl {
							.ot-switch {
								height: 12px!important;
								width: 32px!important;
								top: -6px!important;
								left: -12px!important;

								.ot-switch-nob {
									background-color: $gray07!important;
									border: 1px solid transparent!important;

									&:before {
										top: -5px!important;
										left: -5px!important;
										background-color: $gray06!important;
										width: 20px!important;
										height: 20px!important;
										box-shadow: 0px 1px 1px rgba(7, 29, 43, 0.24)!important;
									}
								}
							}

							input:checked+.ot-switch {
								.ot-switch-nob {
									background-color: #7FB7FA!important;
									border: 1px solid #7FB7FA!important;

									&:before {
										background-color: $blue-bright!important;
										border-color: $blue-bright!important;
									}
								}
							}
						}

						.ot-always-active {
							color: $gray07;
							font-size: $base-unit*.875;
							text-decoration: underline;
							padding-bottom: $base-unit*.9375!important;
							letter-spacing: 0.8px;
						}
					}

					.ot-acc-grpcntr {
						background-color:rgba(0, 0, 0, 0.32)!important;
						border-radius: $base-unit!important;
						padding: $base-unit*1.5;

						.ot-acc-grpdesc {
							font-size: $base-unit*.8125!important;
							line-height: $base-unit*1.25!important;
							color: $gray06!important;
							width: 100%!important;
							margin-bottom: $base-unit!important;
						}

						h5 {
							width: 100%!important;
							color: $white!important;
							font-weight: 700!important;
							margin-bottom: $base-unit*.5!important;
							font-size: $base-unit*.8125!important;
						}

						.ot-acc-grpdesc,
						.ot-subgrp-cntr {
							margin: 0px!important;
							padding: 0px!important;
						}

						.ot-subgrp-cntr {
							border: none!important;
							margin-top: $base-unit!important;
							width: 100%!important;

							.ot-subgrp-tgl {
								display: none!important;
							}

							.ot-subgrp-desc {
								margin: 0px!important;
								color: $gray06!important;
								line-height: $base-unit*1.25!important;
								font-size: $base-unit*.8125!important;
							}
						}
					}

					button {
						&:active,
						&:focus {
							outline: none!important;
						}
					}
				}
			}
		}

		// 2d. Buttons
		// 2d. Buttons
		#accept-recommended-btn-handler {
			margin: 0px $base-unit*1.5 0 0;
			left: 2px;
			line-height:  $base-unit*1.25!important;
			letter-spacing: 1px!important;
			padding: 0 $base-unit*1.75!important;
			height: 56px!important;
			min-height: auto!important;
			font-size: $base-unit!important;

			color: #fff!important;

			&:hover {
				opacity: 0.7!important;

				&:before {
					background-color: #3cd58c !important;
				}
			}

			// 3rd party
			background-color: #0070F6!important;
			border: none!important;
			border-radius: 50px!important;

			&:before {
				background-color: #0070F6!important;
			}
		}

		.ot-pc-refuse-all-handler {
			background: #043E7C!important;
			border: 2px solid #043E7C!important;
			border-radius: 50px;
			margin: 0;
			height: 56px!important;
			min-height: auto!important;
			padding: 0 $base-unit*1.75!important;
			//text-transform: uppercase;
            line-height: $base-unit*1.25!important;
    		letter-spacing: 1px!important;
			display: inline-block!important;
		}

		.ot-pc-footer {
			margin-bottom: 0!important;

			.ot-btn-container {
				text-align: left;
			}

			.save-preference-btn-handler {
				margin: 0px!important;
				padding: 0 $base-unit*1.75!important;
				height: 56px!important;
				min-height: auto!important;

				// 3rd party
				background-color: #0070F6!important;
				border: none!important;
				color: #fff!important;
				border-radius: 50px!important;
				font-size: $base-unit!important;
				line-height: $base-unit*1.25!important;
				letter-spacing: 1px!important;

				&:before {
					background-color: #0070F6!important;
				}

				&:hover {
					opacity: 0.7;
				}
			}
		}

		// 2e.Consent Settings
	}

	// 3. Overlay + Helpers
	.onetrust-pc-dark-filter {
		background: rgba(7, 29, 43, 0.8)!important;
		z-index: $cookie__backdrop--z-index;
	}

	* {
		float: none!important;
	}

	// remobing the animation
	#onetrust-pc-sdk.ot-fade-in,
	.onetrust-pc-dark-filter.ot-fade-in,
	#onetrust-banner-sdk.ot-fade-in {
		animation: none!important;
	}

	body.ot-overflow-hidden {
		overflow: hidden!important;
	}

	// MEDIA QUERY
	@media (max-width: 697px) {
		#onetrust-banner-sdk {
			width: 100%!important;
			max-width: 100%!important;
			bottom: 0%!important;
			top: auto!important;
			padding: $base-unit*4 $base-unit*1.25!important;
			border-radius: 0;
			transform: translateY(0)!important;
			-webkit-transform: translateY(0)!important;


			.banner-header {
				justify-content: center!important;
			}
			#onetrust-policy-title {
				font-size: $base-unit*1.3125!important;
				line-height: $base-unit*1.6875!important;
				margin-bottom: $base-unit*.75!important;
				text-align: center;
			}

			#onetrust-policy-text {
				font-weight: 400!important;
				line-height: $base-unit*1.125!important;
				font-size: $base-unit*.75;
				width: 100%!important;
				text-align: center;
			}

			#onetrust-button-group-parent {
				display: flex!important;
				justify-content: center!important;

				#onetrust-button-group {
						display: flex!important;
						flex-direction: column!important;
						width: $base-unit*18!important;
						align-items: center;

						.banner-actions-container {
							flex-direction: column!important;
							width: 100%!important;
							align-items: center;
						}

						#onetrust-pc-btn-handler, #onetrust-reject-all-handler, #onetrust-accept-btn-handler {
							width: 100%!important;
							max-width: 100%!important;
							margin: 0!important;
							margin-bottom: $base-unit!important;
							font-size: $base-unit*.875!important;
							line-height: $base-unit!important;
							letter-spacing: .8px!important;
							padding: 0 $base-unit*1.25!important;
							height: 40px!important;
							min-height: auto;
							max-width: 240px!important;
						}
				}
			}
		}

		#onetrust-pc-sdk {
			height: 100%!important;
			width: 100%!important;
			top: 0%!important;
			bottom: 0%!important;
			max-width: 100%!important;
			border-radius: 0!important;
			min-width: 100%!important;
			background-color: $blue-dark!important;
			padding: $base-unit*4 $base-unit*1.25!important;

			button {
				&:not(.ot-close-icon) {
					max-width: 240px!important;
				}

			    &.ot-pc-refuse-all-handler {
					max-width: none!important;
				}
			}

			.ot-pc-header {
				display: flex;
				justify-content: center;
			}

			#ot-pc-content {
				padding-right: inherit!important;
				text-align: center;

				#ot-category-title {
					text-align: center;
					font-size: $base-unit*.9375!important;
					line-height: $base-unit*1.25!important;
				}

				#ot-pc-title {
					font-size: $base-unit*1.3125!important;
					text-align: center;
					line-height: $base-unit*1.6875!important;
					margin-bottom: $base-unit*.75!important;
				}

				#ot-pc-desc {
					font-size: $base-unit*.75!important;
					line-height: $base-unit*1.125!important;
				}

				.ot-cat-grp {
					margin-top: $base-unit*2.5!important;

					&.ot-sdk-row {
						button {
							max-width: none!important;
						}
					}

					.ot-accordion-layout {
						.ot-acc-hdr {
							.ot-always-active {
								font-size: $base-unit*.75!important;
								padding-bottom: $base-unit*.9375!important;
								right: 22px;
							}

							.ot-tgl {
								right: 12px;
								top: 35%!important;

								.ot-switch {
									top: -1px!important;
								}
							}

							.ot-arw-cntr {
								top: 1px!important;
								right: -12px!important;
							}

							&.ot-always-active-group {
								.ot-arw-cntr {
									top: 6%!important;
								}
							}
						}
						.ot-acc-grpcntr {
							.ot-subgrp-cntr {
								margin-top: $base-unit*.75!important;
							}

							.ot-subgrp-desc,
							h5,
							.ot-acc-grpdesc {
								font-size: $base-unit*.6875!important;
								line-height: $base-unit!important;;
							}
						}

						.ot-subgrp {
							padding-right: $base-unit*1.5!important;
						}
					}
				}
			}

			.ot-cat-grp {
				.ot-cat-header {
					font-size: $base-unit*.75!important;
					line-height: $base-unit*1.125!important;
				}
				.ot-acc-grpcntr {
					padding: $base-unit!important;
				}
			}

			.ot-pc-footer {
				.ot-btn-container {
					text-align: center;
				}

				.save-preference-btn-handler {
					display: inline-block!important;
					width: 100%!important;
					overflow-x: visible!important;
					padding: 0 $base-unit*1.25!important;
					font-size: $base-unit*.875!important;
					line-height: $base-unit!important;
					letter-spacing: .8px!important;
					height: 40px!important;
					min-height: auto;
				}
			}

			#ot-pc-title {
				text-align: center;
				font-size: $base-unit*1.3125!important;
				line-height: $base-unit*1.6875!important;
				margin-bottom: $base-unit*.75!important;
			}

			#ot-pc-desc {
				font-weight: 400!important;
				line-height: $base-unit*1.125!important;
				font-size: $base-unit*.75!important;
				width: 100%!important;
				text-align: center;
				color: $gray07!important;
			}

			#accept-recommended-btn-handler {
				display: inline-block!important;
				width: 100%!important;
				overflow-x: visible!important;
				margin-right: 0!important;
				margin-left: 0!important;
				padding: 0 $base-unit*1.25!important;
				height: 40px!important;
				min-height: auto;
				font-size: $base-unit*.875!important;
				line-height: $base-unit!important;
				letter-spacing: .8px!important;
				max-width: none!important;
			}

			.ot-pc-refuse-all-handler {
				display: inline-block!important;
				width: 100%!important;
				margin: $base-unit 0 0!important;
				font-size: $base-unit*.875!important;
				line-height: $base-unit!important;
				letter-spacing: .8px!important;
				padding: 0 $base-unit*1.25!important;
				height: 40px!important;
				min-height: auto;
				max-width: none!important;
			}
		}
	}

	.gpc-on {
		display: none!important;
	}

	&.gpc-detected {
		.gpc-on {
			display: block!important;
		}
		.gpc-off {
			display: none!important;
		}
	}
}

body.ot-overflow-hidden {
	overflow: hidden!important;
}

// FOOTER!!!
.footer {
	.container {
		.ot-sdk-show-settings {
			color: #C5D6E0 !important;
			border: 0px !important;
			font-size: 12px !important;
			line-height: 18px!important;
			padding: 0 !important;
			background-color: transparent !important;

			white-space: nowrap !important;

			&:hover {
				color: #fff!important;
				text-decoration: underline;
				border: 0px!important;
				background: none!important;
			}

			&:focus,
			&:active {
				outline: none!important;
			}

			@media (min-width: 992px) {
				font-size: 14px!important;
				line-height: 22px!important;
				//padding: .8em 2em .8em 0!important;
			}
		}
	}
}


// RTL

#onetrust-banner-sdk[dir=rtl] {
	#onetrust-close-btn-container {
		left: 0!important;
		right: auto!important;

		span {
			margin-right: 0px!important;
			margin-left: 8px!important;
		}
	}
}

#onetrust-pc-sdk[dir=rtl] {
	overflow-y: auto;

	#ot-pc-content {
		padding-left: inherit;
	}

	#close-pc-btn-handler {
		left: 32px!important;
		right: auto!important;

		span {
			right: -45px!important;
		}
	}


	#accept-recommended-btn-handler {
		right: 2px!important;
		margin-right: 0px!important;
		margin-left: 16px!important;
	}
	.ot-pc-refuse-all-handler {
		margin-right: 16px!important;
	}

	.ot-tgl{
		right: auto!important;
	}

	.ot-always-active {
		left: 30px!important;
		right: auto!important;
	}

	.ot-arw-cntr {
		left: 0!important;
		right: auto!important;
	}

	.ot-pc-footer {
		.ot-btn-container {
			text-align: right!important;
		}
	}

	input:checked+.ot-switch {
		.ot-switch-nob {

			&:before {
				transform: translateX(20px)!important;
			}
		}
	}
}

.mod-ar-ww,
.mod-ar-sa,
.mod-he-il {
	@media (max-width: 697px) {
		#onetrust-consent-sdk {
			#onetrust-pc-sdk {
				#ot-pc-content {
					padding-right: 0!important;
				}
			}
		}

		#onetrust-button-group-parent {
			#onetrust-button-group {
				#onetrust-reject-all-handler {
					margin-right: 0!important;

				}
			}
		}
	}

	#onetrust-consent-sdk {
		#onetrust-button-group-parent {
			#onetrust-button-group {
				#onetrust-reject-all-handler {
					margin-left: 0!important;
					margin-right: 24px!important;

				}
			}
		}
	}
}



//
//	4. OneTrust Cookie Consent – Banner – US minimalistic design (used in EN-US)
//
//	IMPORTANT: All changes done here has to be applied to V2 version as well (/web/dev/avast/c/v2/vendor/onetrust/onetrust-avast-floating-center.scss)
//
/*! OneTrustMini banner - v1.0.5 */
#oneTrustMini {
	// Keep track of version (some compilers remove all comments entirely)
	&::after { content: "OneTrustMini banner - v1.0.5"; display: none; }

	direction: ltr; // Fix layout on RTL locales
	position: fixed;
	left: 20px;
	bottom: 16px;
	z-index: 999; // has to be lower then 1000 (so it's not overlapping main navigation on mobile)

	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	margin-inline-end: 74px;

	border-radius: 128px;
	transition: all ease-out .2s;

	// normalize box-sizing (This has to be here because of Acadamy pages)
	&,
	* {
		box-sizing: border-box;
	
		&::before,
		&::after {
			box-sizing: border-box;
		}
	}

	@media (min-width: 992px) {
		left: 32px;
		bottom: 48px;
		height: 56px;
	}

	// Background & Outline border
	&::before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		z-index: -1;

		height: 100%;
		width: 100%;

		background: #fff;
		border: 2px solid #071d2b;
		border-radius: 128px;

		transition: all ease-in-out .2s;
	}


	//
	//	Expanded banner
	//
	&.js-expanded,
	&:hover,
	&:focus,
	&:focus-within {
		//min-width: 320px;	// This is defined in Figma BUT it's ruining the animation
		padding: 0 14px;

		@media (min-width: 992px) {
			padding: 0 32px;
		}

		#oneTrustMini__content {
			display: flex;
			gap: 16px;
			opacity: 1;

			@media (min-width: 992px) {
				gap: 24px;
			}
		}

		#oneTrustMini__cookie-icon {
			display: none;
		}
	}

	//
	//	Hide the banner (when clicked on Accept or Reject button)
	//
	&.js-hidden {
		display: none;
	}


	&__cookie-icon {
		width: 40px;
		height: 40px;

		background: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_2353_16054)"><path d="M3.80749 12.329C6.13413 14.6557 9.96812 14.6537 12.2928 12.329C13.1294 11.4925 13.6657 10.4618 13.9012 9.37652C13.1064 9.60949 12.2076 9.4154 11.5857 8.7935C10.8817 8.08949 10.7294 7.01406 11.1288 6.14935C10.0529 6.38391 8.87923 6.08707 8.05013 5.25797C7.22555 4.43339 6.93077 3.25212 7.16582 2.17253C5.92976 2.35178 4.74224 2.90901 3.80749 3.84376C1.49745 6.1538 1.49745 10.019 3.80749 12.329Z" stroke="%23071D2B" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><circle cx="5.61035" cy="7.72852" r="1" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><circle cx="10.5762" cy="2.62903" r="1" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><circle cx="13.7314" cy="6.5813" r="0.5" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><circle cx="8.7998" cy="10.7086" r="0.5" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_2353_16054"><rect width="16" height="16" fill="white"/></clipPath></defs></svg>') 50% 50% no-repeat;
		background-size: 16px;
		border: 2px solid transparent;
		border-radius: 128px;

		@media (min-width: 992px) {
			width: 56px;
			height: 56px;
			background-size: 24px;
		}
	}

	&__content {
		// Initial state: Content is hidden
		display: none;
		opacity: 0;

		align-items: center;

		// Force consistent font across different domains (This is here because of Academy pages)
		&,
		* {
			font-size: 12px;
			line-height: normal;
		}

		color: #071D2B;

		@media (min-width: 992px) {
			&,
			* {
				font-size: 14px;
				line-height: 22px;
			}
		}
	}


	//
	//	Text container
	//
	//&__text {
	//}

	// Make sure that link text and the icon stays on the same line
	&__policy-link {
		position: relative;
		display: inline-block;
		margin-inline-end: 18px;	// Reserved space for the icon

		text-decoration: underline;
		color: #071D2B !important;	// fix for businessportal.avast.com

		&:hover,
		&:focus {
			text-decoration: none;
		}

	
		&::after {
			content: "";
			position: absolute;
			top: 0;
			right: -18px;

			display: block;
			width: 16px;
			height: 16px;

			background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none"><path d="M11.9302 8.47V11.3234C11.9302 12.5385 10.9453 13.5234 9.73024 13.5234H5.40898C4.19396 13.5234 3.20898 12.5385 3.20898 11.3234V7C3.20898 5.78497 4.19396 4.8 5.40898 4.8H8.26024M14.209 2.52344H10.4089M14.209 2.52344V6.32343M14.209 2.52344L7.25903 9.49841" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>') 50% 50% no-repeat;

			pointer-events: none;

			@media (min-width: 992px) {
				top: 3px;
			}
		}
	}


	//
	//	Buttons
	//
	&__buttons {
		flex-shrink: 0;
	
		button {
			border: 0;
			padding: 0;
			background: transparent;
			font-weight: 750;
			text-decoration: underline;
			cursor: pointer;

			// Secondary button (Reject / Manage)
			&:not(:first-child) {
				margin-left: 8px;

				@media (min-width: 992px) {
					margin-left: 24px;
				}
			}

			&:hover,
			&:focus {
				text-decoration: none;
			}
		}
	}
}